const LevelLogo = ({ certification }: { certification?: number }) => {
    const globalColor = { fill: "#313131", strokeWidth: "0px" };

    if (!certification || certification === 1) {
        const cls1 = certification ? globalColor : { fill: "#cccccc", strokeWidth: "0px" };
        return (
            <g>
                <path
                    style={cls1}
                    d="M479.25,128.5l31.25-86.25,35.25,86.25h-66.5ZM363,100.5h-21.25V25h26.25c26,0,39.5,13.5,39.5,36.75,0,25.25-16,38.75-44.5,38.75ZM445.5,192.5h15c-2.5-4-1.75-7.5,3.25-21.25l12.75-35.25h72.25l14.75,35.75c5.75,14,6.5,16.75,4,20.75h29.5c-4-6-6-10.75-9.25-18.75l-64.25-156.25h-22.25c4,10,3.75,18.25.25,27.5l-46.25,124.25c-5.5,14-8,18.5-15,18.5-9.5,0-15.25-8.5-26.5-36.25l-4.75-11.75c-8.5-20.75-18.25-30.75-34.25-33.5,34-1.25,55.25-17,55.25-45,0-27.5-20.75-43.75-57.25-43.75h-57.5c3.5,5.5,4.5,13.5,4.5,22.5v130c0,9-1,17-4.5,22.5h31.5c-4-5.5-5-13.5-5-22.5v-62h10.75c15.75,0,22.5,4.25,30.25,24l10,25.75c10.75,27.5,28.75,34.75,52.75,34.75ZM201.75,189c-40.75,0-67.5-33.25-67.5-84s26.75-84,67.5-84c41,0,68,33.25,68,84s-27,84-68,84ZM201.75,196.5c51.75,0,91-39.25,91-91.5S253.5,13.5,201.75,13.5c-51.5,0-90.5,39.25-90.5,91.5s39,91.5,90.5,91.5ZM0,192.5h31.5c-4-5.5-5-13.5-5-22.5v-62h31c11.75,0,22,1,29.25,5v-17.5c-7.25,4-17.5,5-29.25,5h-31V25h37.25c14.75,0,25,2.75,37,9.75l-3.75-17.25H0c3.5,5.5,4.5,13.5,4.5,22.5v130c0,9-1,17-4.5,22.5Z"
                />
                <g>
                    <path
                        style={cls1}
                        d="M768.3586,59.0459c21.5049,0,35.0735,10.7529,38.2737,31.873l-10.7524,1.4082c-2.6882-14.8486-12.2886-21.6328-26.4973-21.6328-15.8726,0-27.3933,13.6963-27.3933,34.3057,0,21.1211,11.6487,34.1777,27.9055,34.1777,16.3848,0,25.8572-9.6006,28.2893-23.5527l10.6245.6396c-3.3284,20.4805-17.1528,34.6895-40.322,34.6895-25.7292,0-45.186-18.8164-45.186-45.9541s20.9929-45.9541,45.0581-45.9541Z"
                    />
                    <path
                        style={cls1}
                        d="M882.9185,60.8379v10.3682h-39.9375v27.0098h27.6489v10.1123h-27.6489v30.4658h42.1138v10.3682h-59.6509V60.8379h57.4746Z"
                    />
                    <path
                        style={cls1}
                        d="M932.8364,60.8379c24.1929,0,32.2573,9.4727,32.2573,23.6816,0,12.5439-10.4966,19.7129-22.5293,21.249v.3838c10.8809,1.6641,16.5132,7.4238,20.353,20.4805,1.4082,4.4805,6.0166,17.4092,9.9844,22.5293h-18.1768c-4.3521-4.0957-6.9121-13.3125-8.8325-21.377-3.1997-13.1846-5.8882-18.1768-15.8726-18.1768h-11.1367v39.5537h-17.4087V60.8379h31.3618ZM918.8833,100.2637h12.5449c9.7285,0,15.8726-4.2236,15.8726-14.9766,0-10.4961-4.48-14.7207-16.8965-14.7207h-11.521v29.6973Z"
                    />
                    <path
                        style={cls1}
                        d="M1023.0757,149.1621h-17.665v-77.5713h-28.9292v-10.7529h75.6519v10.7529h-29.0576v77.5713Z"
                    />
                    <path style={cls1} d="M1065.6963,60.8379h17.5371v88.3242h-17.5371V60.8379Z" />
                    <path
                        style={cls1}
                        d="M1163.3628,60.8379v10.3682h-41.73v28.6738h27.2651v10.2402h-27.2651v39.042h-17.5371V60.8379h59.2671Z"
                    />
                    <path style={cls1} d="M1178.0796,60.8379h17.5371v88.3242h-17.5371V60.8379Z" />
                    <path
                        style={cls1}
                        d="M1273.9541,60.8379v10.3682h-39.9375v27.0098h27.6489v10.1123h-27.6489v30.4658h42.1138v10.3682h-59.6509V60.8379h57.4746Z"
                    />
                    <path
                        style={cls1}
                        d="M1292.5102,60.8379h30.8496c29.4414,0,47.2344,16.1289,47.2344,44.1621s-19.0732,44.1621-46.0825,44.1621h-32.0015V60.8379ZM1309.9194,71.4629v67.0752h13.4404c18.1768,0,28.9292-10.7529,28.9292-33.5381,0-22.1445-9.6001-33.5371-30.3374-33.5371h-12.0322Z"
                    />
                </g>
                <path
                    style={cls1}
                    d="M1335.5891,10c52.3831,0,94.9998,42.6169,94.9998,95s-42.6167,95-94.9998,95h-577.2908c-52.3831,0-95-42.6169-95-95s42.6169-95,95-95h577.2908M1335.5891,0h-577.2908c-57.99,0-105,47.01-105,105s47.01,105,105,105h577.2908c57.9897,0,104.9998-47.01,104.9998-105S1393.5789,0,1335.5891,0h0Z"
                />
            </g>
        );
    }
    if (certification === 2)
        return (
            <g>
                <path
                    style={globalColor}
                    d="M479.25,128.5l31.25-86.25,35.25,86.25h-66.5ZM363,100.5h-21.25V25h26.25c26,0,39.5,13.5,39.5,36.75,0,25.25-16,38.75-44.5,38.75ZM445.5,192.5h15c-2.5-4-1.75-7.5,3.25-21.25l12.75-35.25h72.25l14.75,35.75c5.75,14,6.5,16.75,4,20.75h29.5c-4-6-6-10.75-9.25-18.75l-64.25-156.25h-22.25c4,10,3.75,18.25.25,27.5l-46.25,124.25c-5.5,14-8,18.5-15,18.5-9.5,0-15.25-8.5-26.5-36.25l-4.75-11.75c-8.5-20.75-18.25-30.75-34.25-33.5,34-1.25,55.25-17,55.25-45,0-27.5-20.75-43.75-57.25-43.75h-57.5c3.5,5.5,4.5,13.5,4.5,22.5v130c0,9-1,17-4.5,22.5h31.5c-4-5.5-5-13.5-5-22.5v-62h10.75c15.75,0,22.5,4.25,30.25,24l10,25.75c10.75,27.5,28.75,34.75,52.75,34.75ZM201.75,189c-40.75,0-67.5-33.25-67.5-84s26.75-84,67.5-84c41,0,68,33.25,68,84s-27,84-68,84ZM201.75,196.5c51.75,0,91-39.25,91-91.5S253.5,13.5,201.75,13.5c-51.5,0-90.5,39.25-90.5,91.5s39,91.5,90.5,91.5ZM0,192.5h31.5c-4-5.5-5-13.5-5-22.5v-62h31c11.75,0,22,1,29.25,5v-17.5c-7.25,4-17.5,5-29.25,5h-31V25h37.25c14.75,0,25,2.75,37,9.75l-3.75-17.25H0c3.5,5.5,4.5,13.5,4.5,22.5v130C4.5,179,3.5,187,0,192.5Z"
                />
                <g>
                    <path
                        style={globalColor}
                        d="M772.8389,124.9688h-30.7214l-8.7043,24.1934h-12.5447l33.7935-89.0918h12.8008l32.3855,89.0918h-18.689l-8.3203-24.1934ZM745.5735,115.3682h23.9373l-11.3926-32.7695h-.6401l-11.9045,32.7695Z"
                    />
                    <path
                        style={globalColor}
                        d="M814.8188,60.8379h30.8496c29.4414,0,47.2344,16.1289,47.2344,44.1621s-19.0732,44.1621-46.0825,44.1621h-32.0015V60.8379ZM832.228,71.4629v67.0752h13.4404c18.1768,0,28.9292-10.7529,28.9292-33.5381,0-22.1445-9.6001-33.5371-30.3374-33.5371h-12.0322Z"
                    />
                    <path
                        style={globalColor}
                        d="M941.1562,126.1211h.6401l23.6812-65.2832h12.6724l-33.1533,88.9639h-13.4404l-31.8735-88.9639h19.0728l22.4009,65.2832Z"
                    />
                    <path
                        style={globalColor}
                        d="M1029.2222,124.9688h-30.7217l-8.7041,24.1934h-12.5449l33.7939-89.0918h12.8003l32.3857,89.0918h-18.689l-8.3203-24.1934ZM1001.957,115.3682h23.937l-11.3926-32.7695h-.6401l-11.9043,32.7695Z"
                    />
                    <path
                        style={globalColor}
                        d="M1142.5015,149.29h-14.8486l-44.0342-69.6348h-.3838v69.5068h-12.2886V60.8379h20.2251l38.6577,59.9072h.6401v-59.9072h12.0322v88.4521Z"
                    />
                    <path
                        style={globalColor}
                        d="M1204.4512,59.0459c21.5054,0,35.0737,10.7529,38.2739,31.873l-10.7524,1.4082c-2.688-14.8486-12.2886-21.6328-26.4971-21.6328-15.873,0-27.3936,13.6963-27.3936,34.3057,0,21.1211,11.6484,34.1777,27.9053,34.1777,16.3848,0,25.8574-9.6006,28.2896-23.5527l10.6245.6396c-3.3281,20.4805-17.1528,34.6895-40.3218,34.6895-25.7295,0-45.1865-18.8164-45.1865-45.9541s20.9932-45.9541,45.0581-45.9541Z"
                    />
                    <path
                        style={globalColor}
                        d="M1319.0112,60.8379v10.3682h-39.9375v27.0098h27.6489v10.1123h-27.6489v30.4658h42.1138v10.3682h-59.6509V60.8379h57.4746Z"
                    />
                    <path
                        style={globalColor}
                        d="M1337.5674,60.8379h30.8496c29.4414,0,47.2344,16.1289,47.2344,44.1621s-19.0732,44.1621-46.0825,44.1621h-32.0015V60.8379ZM1354.9761,71.4629v67.0752h13.4409c18.1768,0,28.9292-10.7529,28.9292-33.5381,0-22.1445-9.6006-33.5371-30.3374-33.5371h-12.0327Z"
                    />
                </g>
                <path
                    style={globalColor}
                    d="M1380.6462,10c52.3831,0,94.9998,42.6169,94.9998,95s-42.6167,95-94.9998,95h-622.3479c-52.3831,0-95-42.6169-95-95s42.6169-95,95-95h622.3479M1380.6462,0h-622.3479c-57.99,0-105,47.01-105,105s47.01,105,105,105h622.3479c57.9897,0,104.9998-47.01,104.9998-105S1438.636,0,1380.6462,0h0Z"
                />
            </g>
        );
    if (certification === 3)
        return (
            <g>
                <path
                    style={globalColor}
                    d="M479.25,128.5l31.25-86.25,35.25,86.25h-66.5ZM363,100.5h-21.25V25h26.25c26,0,39.5,13.5,39.5,36.75,0,25.25-16,38.75-44.5,38.75ZM445.5,192.5h15c-2.5-4-1.75-7.5,3.25-21.25l12.75-35.25h72.25l14.75,35.75c5.75,14,6.5,16.75,4,20.75h29.5c-4-6-6-10.75-9.25-18.75l-64.25-156.25h-22.25c4,10,3.75,18.25.25,27.5l-46.25,124.25c-5.5,14-8,18.5-15,18.5-9.5,0-15.25-8.5-26.5-36.25l-4.75-11.75c-8.5-20.75-18.25-30.75-34.25-33.5,34-1.25,55.25-17,55.25-45,0-27.5-20.75-43.75-57.25-43.75h-57.5c3.5,5.5,4.5,13.5,4.5,22.5v130c0,9-1,17-4.5,22.5h31.5c-4-5.5-5-13.5-5-22.5v-62h10.75c15.75,0,22.5,4.25,30.25,24l10,25.75c10.75,27.5,28.75,34.75,52.75,34.75ZM201.75,189c-40.75,0-67.5-33.25-67.5-84s26.75-84,67.5-84c41,0,68,33.25,68,84s-27,84-68,84ZM201.75,196.5c51.75,0,91-39.25,91-91.5S253.5,13.5,201.75,13.5c-51.5,0-90.5,39.25-90.5,91.5s39,91.5,90.5,91.5ZM0,192.5h31.5c-4-5.5-5-13.5-5-22.5v-62h31c11.75,0,22,1,29.25,5v-17.5c-7.25,4-17.5,5-29.25,5h-31V25h37.25c14.75,0,25,2.75,37,9.75l-3.75-17.25H0c3.5,5.5,4.5,13.5,4.5,22.5v130C4.5,179,3.5,187,0,192.5Z"
                />
                <g>
                    <path
                        style={globalColor}
                        d="M760.4299,60.8379c24.8333,0,33.9216,8.832,33.9216,25.6016,0,15.8721-10.7524,24.5771-33.4097,24.5771h-11.2644v38.1455h-17.6648V60.8379h28.4172ZM749.6775,102.6963h9.7285c12.0325,0,17.4087-5.7607,17.4087-16.001,0-11.2646-4.9922-16.001-17.2808-16.001h-9.8564v32.002Z"
                    />
                    <path
                        style={globalColor}
                        d="M841.0693,60.8379c24.1929,0,32.2573,9.4727,32.2573,23.6816,0,12.5439-10.4966,19.7129-22.5293,21.249v.3838c10.8809,1.6641,16.5132,7.4238,20.353,20.4805,1.4082,4.4805,6.0166,17.4092,9.9844,22.5293h-18.1768c-4.3521-4.0957-6.9121-13.3125-8.8325-21.377-3.1997-13.1846-5.8882-18.1768-15.8726-18.1768h-11.1362v39.5537h-17.4092V60.8379h31.3618ZM827.1167,100.2637h12.5444c9.7285,0,15.8726-4.2236,15.8726-14.9766,0-10.4961-4.48-14.7207-16.8965-14.7207h-11.5205v29.6973Z"
                    />
                    <path
                        style={globalColor}
                        d="M934.3804,59.0459c24.5771,0,45.6982,18.4326,45.6982,45.9541s-21.1211,45.9541-45.6982,45.9541-45.6978-18.4326-45.6978-45.9541,21.1206-45.9541,45.6978-45.9541ZM934.3804,138.1533c16.001,0,27.3936-12.5439,27.3936-33.1533,0-20.7373-11.3926-33.1533-27.3936-33.1533-16.1284,0-27.521,12.416-27.521,33.1533,0,20.6094,11.3926,33.1533,27.521,33.1533Z"
                    />
                </g>
                <path
                    style={globalColor}
                    d="M945.073,10c52.3831,0,94.9998,42.6167,94.9998,95s-42.6167,95-94.9998,95h-186.7747c-52.3831,0-95-42.6167-95-95s42.6169-95,95-95h186.7747M945.073,0h-186.7747c-57.99,0-105,47.0103-105,105s47.01,105,105,105h186.7747c57.9897,0,104.9998-47.0103,104.9998-105S1003.0627,0,945.073,0h0Z"
                />
            </g>
        );
    if (certification === 5)
        return (
            <g>
                <path
                    style={globalColor}
                    d="M3415,820c106-94,179-249,179-410S3521,94,3415,0l-7,7c65,87,108,242,108,403s-43,316-108,403l7,7ZM3098,421l198-293c24-36,36-52,51-68h-69c6,20-4,38-24,68l-175,263,19,30ZM2813,760h69c-6-20,4-38,24-68l180-271-19-30-203,301c-24,36-36,52-51,68ZM3233,760h130c-15-16-27-32-49-68l-335-555c-19-31-31-55-25-77h-128c13,16,25,32,47,68l335,555c19,31,31,55,25,77ZM2761,820l7-7c-65-87-108-242-108-403s43-316,108-403l-7-7c-106,94-179,249-179,410s73,316,179,410ZM1917,504l125-345,141,345h-266ZM1452,392h-85V90h105c104,0,158,54,158,147,0,101-64,155-178,155ZM1782,760h60c-10-16-7-30,13-85l51-141h289l59,143c23,56,26,67,16,83h118c-16-24-24-43-37-75L2094,60h-89c16,40,15,73,1,110l-185,497c-22,56-32,74-60,74-38,0-61-34-106-145l-19-47c-34-83-73-123-137-134,136-5,221-68,221-180s-83-175-229-175h-230c14,22,18,54,18,90v520c0,36-4,68-18,90h126c-16-22-20-54-20-90v-248h43c63,0,90,17,121,96l40,103c43,110,115,139,211,139ZM807,746c-163,0-270-133-270-336s107-336,270-336,272,133,272,336-108,336-272,336ZM807,776c207,0,364-157,364-366S1014,44,807,44s-362,157-362,366,156,366,362,366ZM0,760h126c-16-22-20-54-20-90v-248h124c47,0,88,4,117,20v-70c-29,16-70,20-117,20h-124V90h149c59,0,100,11,148,39l-15-69H0c14,22,18,54,18,90v520c0,36-4,68-18,90Z"
                />
            </g>
        );
    return null;
};
export const AdvisorBadge = ({ id = 0, className = "" }: { id?: number; className?: string }) => {
    const { width, height } =
        id === 5 ? { width: "3594", height: "820" } : { width: "1485", height: "210" };

    return (
        <svg
            version="1.1"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            className={className}
            data-testid="badge"
        >
            <LevelLogo certification={id} />
        </svg>
    );
};
