import { CERTIFICATION_CONFIG } from "lib/constants/certification.constants";
import { CertificationFullConfig } from "lib/types";

//NOTE: Giving this copy keeps changing, leaving this hook to either create a service that returns the data or allow versioning in one centralized point
export const useCertificationConfig = (): {
    config: CertificationFullConfig;
} => {
    return {
        config: CERTIFICATION_CONFIG
    };
};
