import React, { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

/** A card-styled container */
export const Card = ({ className, ...divProps }: HTMLAttributes<HTMLDivElement>): JSX.Element => (
    <div
        className={twMerge(
            "flex flex-col bg-white rounded-xl text-left p-4 border border-solid border-lightGreyBorder",
            className
        )}
        {...divProps}
    />
);
