import Link from "next/link";
import { twMerge } from "tailwind-merge";

export const LearnMoreLink = ({ className = "" }: { className?: string }) => {
    return (
        <Link href="/certifications" className={twMerge(`text-link text-sm ${className}`)}>
            Learn more about the benefits of status
        </Link>
    );
};
