import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export const Subtitle = ({
    children,
    className = ""
}: {
    children: ReactNode;
    className?: string;
}) => {
    return (
        <h4 className={twMerge(`text-secondaryDark uppercase text-xs font-medium mb-1 ${className}`)}>
            {children}
        </h4>
    );
};
