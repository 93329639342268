import { UserProfile } from "api/user/profile";
import { AdvisorBadge } from "components/AdvisorBadge";
import { Subtitle } from "./Subtitle";
import { LearnMoreLink } from "./LearnMoreLink";
import { ReactElement } from "react";
import { useCertificationConfig } from "hooks/use-certification-config";
import { Card } from "components/common/v2/Card";
import { twMerge } from "tailwind-merge";

export const AdvisorLevelDetails = ({
    userProfile,
    showLearnMore = false,
    className
}: {
    userProfile: UserProfile;
    showLearnMore?: boolean;
    className?: string;
}) => {
    const { config: certificationConfig } = useCertificationConfig();
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentLevel = userProfile.certification_type_id || 0;
    const levelConfig = certificationConfig[currentLevel];
    let upLevelTitle = `Maintain your ${levelConfig.shortName} status`;
    let upLevelSubtitle = "Grow your business";
    let steps = levelConfig.tasksToKeepLevel;
    let nextLevelLimit = levelConfig.limit;
    if (levelConfig.nextLevel) {
        const nextLevelConfig = certificationConfig[levelConfig.nextLevel];
        upLevelTitle = `Qualify for ${
            levelConfig.nextLevelPrefix ? `${levelConfig.nextLevelPrefix} ` : ""
        }${nextLevelConfig.shortName}${
            levelConfig.nextLevelPostfix ? ` ${levelConfig.nextLevelPostfix}` : ""
        }`;
        upLevelSubtitle = "Next up";
        steps = nextLevelConfig.tasksToAchieveLevel;
        nextLevelLimit = nextLevelConfig.limit;
    }
    return (
        <Card className={twMerge("flex flex-col lg:flex-row lg:gap-x-6 p-8", className)}>
            <div className="flex items-center">
                <div className="flex flex-col items-start gap-4 md:gap-6">
                    <Subtitle className="uppercase text-secondaryDark text-xs m-0">
                        {currentYear} Status
                    </Subtitle>
                    <AdvisorBadge id={currentLevel} className="h-[29px] w-auto" />
                    {nextLevelLimit && <p className="text-label text-xs">{nextLevelLimit}</p>}
                </div>
            </div>
            <hr className="bg-slate-300 w-full lg:hidden mx-auto my-4" />
            <div className="lg:border-l lg:pl-6">
                <Subtitle>{upLevelSubtitle}</Subtitle>
                <p className="text-md font-normal mb-2">{upLevelTitle}</p>
                {steps && steps.length > 0 && (
                    <ul className="list-disc ml-5 text-secondaryDark text-left lg:px-0 text-[12px]">
                        {steps?.map((step: string | ReactElement, index: number) => (
                            <li key={index}>{step}</li>
                        ))}
                    </ul>
                )}
                {showLearnMore && <LearnMoreLink className="block mt-3" />}
            </div>
        </Card>
    );
};
