import { CertificationConfig, CertificationFeature } from "lib/types";
import Link from "next/link";

export const CERTIFICATIONS: { id: number; name: string }[] = [
    { id: 1, name: "Certified" },
    { id: 2, name: "Advanced" },
    { id: 3, name: "Pro" },
    { id: 5, name: "Fora X" }
];

export const CERTIFICATION_CONFIG: {
    [key: number]: CertificationConfig;
} = {
    0: {
        color: "#E7E7E7",
        fontColor: "#BBBBBB",
        name: "Not yet certified",
        shortName: "All",
        limit: "You have from Jan 1 to Dec 31 to earn status",
        nextLevel: 1,
        features: [
            "portal",
            "training",
            "foras-nyc-conference",
            "booking-bootcamp",
            "advisor-login"
        ]
    },
    1: {
        color: "#749F90",
        fontColor: "#525B58",
        name: "Certified",
        shortName: "Certified",
        limit: "You have from Jan 1 to Dec 31 to earn status",
        tasksToAchieveLevel: [
            "Complete at least one booking",
            <>
                Complete{" "}
                <Link href="/training/certified/certified-quiz" className="text-link">
                    Certified quiz
                </Link>
            </>
        ],
        nextLevel: 2,
        features: [
            "portal",
            "training",
            "badge",
            "foras-nyc-conference",
            "fora-organized-site-visits",
            "booking-bootcamp",
            "advisor-login"
        ],
        featureValues: {
            "commission-split": "70/30"
        }
    },
    2: {
        color: "#689EB2",
        fontColor: "#3E494F",
        name: "Advanced",
        shortName: "Advanced",
        limit: "You have from Jan 1 to Dec 31 to earn status",
        tasksToAchieveLevel: [
            <>
                Complete Advanced{" "}
                <Link href="/training#advanced" className="text-link">
                    training
                </Link>{" "}
                and{" "}
                <Link
                    href="/training/advanced/advanced-take-the-advanced-quiz/advanced-take-the-advanced-quiz"
                    className="text-link"
                >
                    quiz
                </Link>
            </>,
            "Demonstrate $40k in commissionable bookings within the calendar year",

            <>
                Host two client reviews on{" "}
                <a
                    href="https://www.foratravel.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-link"
                >
                    foratravel.com
                </a>
            </>,

            "Book at least 5 clients all time",
            "Book at least 3 partner types all time (hotel, cruise, insurance, tour, etc.)"
        ],
        nextLevel: 3,
        features: [
            "portal",
            "training",
            "badge",
            "foras-nyc-conference",
            "fora-organized-site-visits",
            "local-partner-events",
            "booking-bootcamp",
            "advisor-login",
            "peer-mmentorship",
            "connect-with-a-pro"
        ],
        featureValues: {
            "commission-split": "70/30"
        }
    },
    3: {
        color: "#42413F",
        fontColor: "#000",
        name: "Pro",
        shortName: "Pro",
        limit: "You have from Jan 1 to Dec 31 to earn status",
        tasksToAchieveLevel: [
            "Demonstrate $100k in commissionable bookings within the calendar year",
            "Host five client reviews on foratravel.com",
            "Book at least 10 clients all time"
        ],
        nextLevel: 5,
        nextLevelPostfix: "(by invite only)",
        features: [
            "portal",
            "training",
            "badge",
            "foras-nyc-conference",
            "fora-organized-site-visits",
            "fora-and-industry-fam-trips",
            "local-partner-events",
            "advisor-login",
            "virtuoso-login",
            "preferred-partners",
            "booking-bootcamp",
            "office-hours",
            "email-priority-response-times",
            "iata-card",
            "internal-flight-desk",
            "peer-mmentorship"
        ],
        featureValues: {
            "commission-split": (
                <>
                    <div className="mb-1">
                        <div>70/30</div> (up to $300k)
                    </div>
                    <div>
                        <div>80/20</div> (after $300k)
                    </div>
                </>
            )
        }
    },
    5: {
        color: "#673535",
        fontColor: "#000",
        name: "Fora X",
        shortName: "Fora X",
        limit: "You have from Jan 1 to Dec 31 to earn status",
        tasksToAchieveLevel: [
            "Exceed $500k in bookings in 2024 - this threshold is subject to change year over year",
            "Demonstrate leadership in the Fora community & actively contribute to Fora's culture of support and inclusivity",
            "Invited by Fora HQ to participate in program"
        ],
        tasksToKeepLevel: [
            "Demonstrate significant booking growth in the calendar year. Volume must be within top 3% of Fora Advisors."
        ],
        features: [
            "portal",
            "training",
            "badge",
            "foras-nyc-conference",
            "fora-organized-site-visits",
            "fora-and-industry-fam-trips",
            "local-partner-events",
            "direct-contact-with-fora-hq-team",
            "financial-management-advice",
            "advisor-login",
            "virtuoso-login",
            "preferred-partners",
            "virtuoso-travel-week",
            "industry-conferences",
            "booking-bootcamp",
            "office-hours",
            "email-priority-response-times",
            "iata-card",
            "press-opportunities",
            "internal-flight-desk",
            "peer-mmentorship"
        ],
        featureValues: {
            "commission-split": "80/20"
        }
    }
};

export const CERTIFICATION_FEATURES: CertificationFeature[] = [
    {
        id: "general",
        name: "General",
        features: [
            { id: "portal", name: "Access to Fora Portal" },
            { id: "training", name: "Access to Fora training" },
            { id: "badge", name: "Status badge" },
            {
                id: "commission-split",
                name: "Commission split*"
            }
        ]
    },
    {
        id: "network-with-us",
        name: "Network with us",
        features: [
            {
                id: "foras-nyc-conference",
                name: "Invite to Fora's NYC conference"
            },
            {
                id: "fora-organized-site-visits",
                name: "Access to Fora organized site visits"
            },
            {
                id: "fora-and-industry-fam-trips",
                name: "Access to Fora and industry FAM trips"
            }
        ]
    },
    {
        id: "mentorship",
        name: "Mentorship",
        features: [
            {
                id: "peer-mmentorship",
                name: "Peer mentorship in 1:1 calls and certification-specific Forum groups"
            },
            {
                id: "connect-with-a-pro",
                name: "Optional call to connect with a Pro"
            }
        ]
    },
    {
        id: "fora-hq",
        name: "Fora HQ",
        features: [
            {
                id: "local-partner-events",
                name: "Invites to local partner events with Fora HQ"
            },
            {
                id: "direct-contact-with-fora-hq-team",
                name: "Access to direct contact with Fora HQ team"
            },
            {
                id: "financial-management-advice",
                name: "Financial management advice in partnership with Fora HQ"
            }
        ]
    },
    {
        id: "travel-partner-perks",
        name: "Travel Partner Perks",
        features: [
            {
                id: "advisor-login",
                name: "Access to Expedia TAAP, Tablet Pro, & more"
            },
            {
                id: "internal-flight-desk",
                name: "Access to Fora's internal flight desk"
            },
            {
                id: "virtuoso-login",
                name: "Virtuoso login, profile & training"
            },
            {
                id: "preferred-partners",
                name: "Individual logins to specific preferred partners"
            },
            {
                id: "virtuoso-travel-week",
                name: "Access to Virtuoso Travel Week"
            },
            {
                id: "industry-conferences",
                name: "Access to invite-only industry conferences"
            }
        ]
    },
    {
        id: "support",
        name: "Support",
        features: [
            { id: "booking-bootcamp", name: "Booking Bootcamp - Office Hours" },
            { id: "office-hours", name: "Pro only Office Hours" },
            {
                id: "email-priority-response-times",
                name: "Priority response times from support@fora.travel"
            }
        ]
    },
    {
        id: "additional-perks",
        name: "Additional Perks",
        features: [
            { id: "iata-card", name: "IATA card" },
            {
                id: "press-opportunities",
                name: "Prioritized for press opportunities"
            }
        ]
    }
];
